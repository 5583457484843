import React, { useState, useContext, useEffect, useCallBack } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import "./NavMenu.scss";
import UsuarioContext from "../../context/usuario/usuarioContext";
import RouteConfig from "../../routeConfig";
interface Props {
  closeMenuAfterclickpage?: () => void;
}

export const NavMenuLinks = (props: Props) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isUsuarioAutenticado, usuario, logoffUsuarioLogado } = useContext(UsuarioContext);
  const { alterarSenha, meuTitulo } = RouteConfig;

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  const onClickSair = (event) => {
    event.preventDefault();
    logoffUsuarioLogado();
  };

  const onClickMinhaConta = () => {
    history.push(alterarSenha.path)
  };

  const onClickMeuTitulo = () => {
    history.push(meuTitulo.path)
  };


  return (
    <>
      <ul className="navbar-nav flex-grow mx-auto">
        {/* <NavItem>
          <NavLink
            tag={Link}
            className="text-light"
            to="/"
            onClick={props.closeMenuAfterclickpage}
          >
            Home
          </NavLink>
        </NavItem> */}
      </ul>
      <ul className="navbar-nav flex-grow">
        {isUsuarioAutenticado === true ? (
            <NavItem>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  className="btn btn-primary text-light iconnav btn-dropdown mb-2 mb-md-0 mt-1 mt-md-0 btn-myaccount"
                  caret
                >
                  <MdAccountCircle size={17} /> Olá, {usuario.nome} - Título {usuario.titulo}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="link" onClick={() => onClickMeuTitulo()}>Meu Título</DropdownItem>
                  <DropdownItem className="link" onClick={() => onClickMinhaConta()}>Minha Conta</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className="link-red" onClick={(e) => onClickSair(e)}>Sair</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
        ) : (<></>)}
      </ul>
    </>
  );
};