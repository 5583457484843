import React, { useContext, useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import ApiService from "../../context/services/api/apiService";
import UsuarioContext from "../../context/usuario/usuarioContext";
import { Layout } from "../../componentes/Layout";
import LoadingBar from 'react-top-loading-bar';

import {
  SocioResponseModel,
  TituloResponseModel
} from "../../context/services/api/models";

export const MeuTitulo = () => {
  const loadingBar = useRef(null);

  const [tituloDados, setTituloDados] = useState<TituloResponseModel>([]);
  const [tituloContato, setTituloContato] = useState("");
  const [socios, setSocios] = useState<SocioResponseModel[]>([]);

  useEffect(async () => {
    loadingBar.current.continuousStart();
    let resp = await ApiService.getTituloESocios();

    setTituloDados(resp.titulo);
    setSocios(resp.socios);
    setTituloContato(resp.socios.find((x) => x.fkGrauDep === 0).email);

    loadingBar.current.complete();
  }, []);

  return (
    <Layout>
      <LoadingBar
                height={4}
                color='#ff9138'
                ref={loadingBar}
                />
      <div className="pagina-container">
      
        <div className="headerbar p-3 mb-3">
          <Container>
            <Row>
              <Link to="/home" className="btn-back">
                <IoMdArrowRoundBack size={16} />
                Voltar
              </Link>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <Col>
              <h2>Informações do meu Título</h2>
              <div className="container-table-infotitulo">
                <div className="wrap-table-100">
                  <div className="table-socios">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1">Título</th>
                          <th className="column2">Situação</th>
                          <th className="column3">Email</th>
                          <th className="column4">Endereço</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="column1">{`${tituloDados.id ?? ''} - ${tituloDados.tipoTitulo ?? ''}`}</td>
                          <td className="column2">
                            {tituloDados.inativo ? "INATIVO" : "ATIVO"}
                          </td>
                          <td className="column3">{tituloContato}</td>
                          <td className="column4">
                            {" "}
                            {`${tituloDados.enderecoRes ?? ''} - ${tituloDados.cidadeRes ?? ''} / ${tituloDados.estadoRes ?? ''}`}
                            <Link
                              className="icon-edit"
                              to="editar-endereço"
                              data-toggle="tooltip"
                              title="Editar endereço"
                            >
                              <MdEdit />
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h2 className="mt-3">Sócios</h2>
              <div className="container-table-socios">
                <div className="wrap-table-100">
                  <div className="table-socios">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1">Nome do Sócio</th>
                          <th className="column2">T/D</th>
                          <th className="column3">Situação</th>
                        </tr>
                      </thead>
                      {socios.map((item) => (
                        <tbody>
                          <tr>
                            <td className="column1">{item.nome}</td>
                            <td className="column2">{item.tipoSocio}</td>
                            <td className="column3">{item.situacao}</td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
              <span className="meu-titulo-detalhes">
                <b className="m-0">Para inclusão e/ou exclusão de dependentes, o titular deve entrar em contato com a secretaria</b>
                <p className="m-0">Telefone: 17-3279-3500 Ramal: 517 ou 518</p>
                <p className="m-0">E-mail: secretaria@termas.com.br</p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};
