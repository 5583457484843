import { useContext, useReducer } from "react";
import UsuarioContext from "./usuarioContext";
import UsuarioReducer from "./usuarioReducer";
import ApiService from "../services/api/apiService";
import { AutenticarModel, UsuarioModel } from "../../models";
import {
  AUTENTICATE_USER,
  GET_USER,
  LOGOFF_USUARIO,
} from "../types";
import { UsuarioApiResponseModel, UsuarioResponseModel } from "../services/api/models";
import { toast } from "react-toastify";

export interface IUsuarioModelState {
  usuario: UsuarioModel;
  autenticarUsuarioAsync?: (autenticarModel: AutenticarModel) => void;
  logoffUsuarioLogado?: () => void;
  getDadosUsuarioLogado?: () => void;
  carregarDadosUsuario?: () => void;
  isUsuarioAutenticado: boolean;
  loading: boolean;
}

const UsuarioState = (props) => {
  const initialState = {
    isUsuarioAutenticado: false,
    usuario: undefined
  };

  const [state, dispatch] = useReducer(UsuarioReducer, initialState);

  if (localStorage["usuario"]) {
    var usuarioData = JSON.parse(localStorage["usuario"]) as UsuarioApiResponseModel;

    if (usuarioData) {
      initialState.usuario = usuarioData;
      initialState.isUsuarioAutenticado = true;
    }
  }

  const carregarDadosUsuario = async () => {
    var usuarioAutenticadoLocalJson = localStorage.getItem(
      "usuario_autenticado"
    );

    if (usuarioAutenticadoLocalJson) {
      const usuarioAutenticadoLocal = JSON.parse(usuarioAutenticadoLocalJson);

      const { token, refreshToken, expirationTime } = usuarioAutenticadoLocal;
  
      if (usuarioAutenticadoLocal) {
        
        initialState.usuario = usuarioAutenticadoLocal
        initialState.isUsuarioAutenticado = true
        
        ApiService.setTokenUsuarioAutenticado(
          token,
          refreshToken,
          expirationTime
        );

        try {
          await ApiService.atualizarUsuarioLogadoAsync();
          const usuarioLogadoDados = await getDadosUsuarioLogadoAsync();
          dispatch({
            type: "",
            payload: {
              isUsuarioAutenticado: true,
              usuario: {
                ...usuarioLogadoDados
              }
            },
          });
        } catch (error) {}
      }
    }
  };

  const autenticarUsuarioAsync = async (autenticarModel: AutenticarModel) => {
    try {
      const usuarioResponseModel = await ApiService.autenticarUsuarioAsync(
        autenticarModel
      );

      ApiService.setTokenUsuarioAutenticado(
        usuarioResponseModel.token,
        usuarioResponseModel.refreshToken,
        usuarioResponseModel.expirationTime
      );

      localStorage.setItem(
        "usuario",
        JSON.stringify({ nome: usuarioResponseModel.nome, titulo: usuarioResponseModel.titulo })
      );

      dispatch({
        type: AUTENTICATE_USER,
        payload: {
          usuario: { nome: usuarioResponseModel.nome, titulo: usuarioResponseModel.titulo },
          isUsuarioAutenticado: true
        },
      });
    } catch (error) {
      toast.error("Houve um erro ao tentar logar. Verifique seus dados de login e tente novamente.");
    }
  };

  const logoffUsuarioLogado = () => {
    limparDadosUsuarioLogado();
    
    window.location.href = '/';
    dispatch({
      type: LOGOFF_USUARIO,
      payload: {
        ...initialState,
      },
    });
  };

  const limparDadosUsuarioLogado = () => {
    localStorage.removeItem("usuario");
    localStorage.removeItem("usuario_autenticado");
  };

  const getDadosUsuarioLogadoAsync = async (): Promise<UsuarioResponseModel> => {
      const usuarioApiResponseModel = await ApiService.getUsuarioAsync();
      return usuarioApiResponseModel;
    };

  const getDadosUsuarioLogado = async () => {
    const usuarioResponseModel = await getDadosUsuarioLogadoAsync();
    
    dispatch({
      type: GET_USER,
      payload: {
        usuario: {
          ...usuarioResponseModel,
        },
      },
    });
  };

  return (
    <UsuarioContext.Provider
      value={{
        ...state,
        autenticarUsuarioAsync,
        logoffUsuarioLogado,
        getDadosUsuarioLogado,
        carregarDadosUsuario,
      }}
    >
      {props.children}
    </UsuarioContext.Provider>
  );
};

export default UsuarioState;