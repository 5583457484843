import React from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Boleto from "../../componentes/boleto/Boleto";

export const ImprimirBoleto = () => {
  const location = useLocation();

  if (!location.state.boleto)
    toast.error("Houve um erro ao carregar as informações do boleto.");

  return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    
        width: "100%",
        height: "100vh",
        backgroundColor: "#ffff"
      }}>
        
        <Boleto boletoDados={location.state.boleto}></Boleto>
    </div>
  );
};
