import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { MdSave } from "react-icons/md";
import { AlterarDadosModel, ContatoModel } from "../../models";
import ApiService from "../../context/services/api/apiService";
import { SocioResponseModel } from "../../context/services/api/models";
import { Layout } from "../../componentes/Layout";
import LoadingBar from 'react-top-loading-bar';

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Informe o seu e-mail.")
    .email("Informe um e-mail válido.")
    .max(50, "Informe um e-mail válido. (caracateres)"),
  telefoneResidencial: Yup.string()
    .trim()
    .min(10, "Informe um número de telefone residencial (com o DDD).")
    .max(15, "Informe um número de telefone residencial (com o DDD)."),
  telefoneMovel: Yup.string()
    .trim()
    .required("Inform seu telefone móvel.")
    .min(10, "Informe um número de telefone móvel (com o DDD).")
    .max(15, "Informe um número de telefone móvel (com o DDD)."),
});

export const AlterarDados = () => {
  const loadingBar = useRef(null);

  const [nomeSocio, setNomeSocio] = useState("");
  const [emailSocio, setEmailSocio] = useState("");
  const [foneSocio, setFoneSocio] = useState("");

  let history = useHistory();

  useEffect(async () => {
    loadingBar.current.continuousStart();

    let socio: SocioResponseModel = await ApiService.getSocioAsync();
    setEmailSocio(socio.email);
    setFoneSocio(socio.fone);
    setNomeSocio(socio.nome);
  
    loadingBar.current.complete();
  }, []);

  const onSubmitSalvar = async (values) => {
    let foneMov = values.telefoneMovel.replace(/\D/g, "");
    let foneRes = values.telefoneResidencial.replace(/\D/g, "");

    let obj: ContatoModel = {
      email: values.email,
      dddMov: foneMov.substr(0, 2),
      telefoneMov: foneMov.substr(2),
      dddRes: foneRes === '' ? 0 : foneRes.substr(0, 2),
      telefoneRes: foneRes === '' ? 0 : foneRes.substr(2),
      contatoPorWhatsapp: values.contatoPorWhatsapp,
      contatoPorEmail: values.contatoPorEmail
    };

    try {
      await ApiService.inserirSolicitacaoContato(obj);
      toast.success("Dados alterados com sucesso.");
    } catch (error) {
      toast.error("Houve um erro ao finalizar a alteração dos dados.");
    }
  };

  return (
    <Layout>
      <LoadingBar
                height={4}
                color='#ff9138'
                ref={loadingBar}
                />
      <div className="pagina-container">
        <div className="headerbar p-3 mb-3">
          <Container>
            <Row>
              <Link to="/home" className="btn-back">
                <IoMdArrowRoundBack size={16} />
                Voltar
              </Link>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <Col>
              <h2>
                Meus dados
              </h2>
              <p className="text-muted" style={{ fontSize: "20px"}}>
                Dados de contato do sócio titular
              </p>
              <div>
                <Container className="form-alterar-dados mb-3">
                  <h4>Dados atuais</h4>
                  <Row>
                      <Col>
                        <label for="nomeAtualSocio" className="sr-only">
                          Nome do sócio
                        </label>
                        <input
                          type="text"
                          value={nomeSocio}
                          id="nomeAtualSocio"
                          placeholder="Nome atual do sócio"
                          className="form-control input-light-ec mb-2"
                          disabled
                        />
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                        <label for="emailAtualSocio" className="sr-only">
                          Email
                        </label>
                        <input
                          type="text"
                          value={emailSocio}
                          id="emailAtualSocio"
                          placeholder="Email atual do sócio"
                          className="form-control input-light-ec mb-2"
                          disabled
                        />
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                        <label for="foneAtualSocio" className="sr-only">
                          Telefone
                        </label>
                        <input
                          type="text"
                          value={foneSocio}
                          id="foneAtualSocio"
                          placeholder="Telefone atual do sócio"
                          className="form-control input-light-ec mb-2"
                          disabled
                        />
                    </Col>
                  </Row>
                </Container>
              </div>
              <div>
                <Formik
                  initialValues={{
                    nome: '',
                    email: '',
                    telefoneMovel: '',
                    telefoneResidencial: '',
                    contatoPorWhatsapp: false,
                    contatoPorEmail: false
                  }}
                  validationSchema={FormSchema}
                  onSubmit={async (
                    model: AlterarDadosModel,
                    { setSubmitting }
                  ) => {
                    setSubmitting(false);
                    onSubmitSalvar(model);
                    history.push("/home");
                  }}
                >
                  {(formik) => {
                    const { isSubmitting } = formik;
                    return (
                      <Form className="form-alterar-dados">
                        <h4>Atualizar dados</h4>
                        <FormGroup>
                          <Label for="inputNomeSocio" className="sr-only">
                            Nome do sócio
                          </Label>
                          <Field
                            type="text"
                            name="nome"
                            value={nomeSocio}
                            id="inputNomeSocio"
                            placeholder="Nome do Sócio"
                            className="form-control input-light-ec mb-2"
                            disabled
                          />
                          <ErrorMessage
                            name="nome"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="inputEmail" className="sr-only">
                            E-mail do sócio
                          </Label>
                          <Field
                            type="email"
                            name="email"
                            id="inputEmail"
                            placeholder="E-mail do sócio"
                            className="form-control input-light-ec mb-2"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="inputTelefoneMovel" className="sr-only">
                            Telefone Móvel
                          </Label>
                          <Field
                            type="text"
                            name="telefoneMovel"
                            id="inputTelefoneMovel"
                            placeholder="Telefone Móvel"
                            className="form-control input-light-ec mb-2"
                          />

                          <ErrorMessage
                            name="telefoneMovel"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label
                            for="inputTelefoneResidencial"
                            className="sr-only"
                          >
                            Telefone da Residência
                          </Label>
                          <Field
                            type="text"
                            name="telefoneResidencial"
                            id="inputTelefoneResidencial"
                            placeholder="Telefone da Residência"
                            className="form-control input-light-ec mb-2"
                          />

                          <ErrorMessage
                            name="telefoneResidencial"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label check>
                            <Field
                              type="checkbox"
                              name="contatoPorWhatsapp"
                              className="mt-2"
                            />{" "}
                          </Label>
                          <span  
                            style={{
                              fontSize: "17px"
                            }}
                          >{" "}
                            Quero receber por WhatsApp ofertas e novidades dos Thermas dos Laranjais.
                          </span>
                        </FormGroup>
                        <FormGroup>
                          <Label check>
                            <Field
                              type="checkbox"
                              name="contatoPorEmail"
                              className="mt-2"
                            />{" "}
                          </Label>
                          <span  
                            style={{
                              fontSize: "17px"
                            }}
                          >{" "}
                            Quero receber por email ofertas e novidades dos Thermas dos Laranjais.
                          </span>
                        </FormGroup>
                        <Button
                          disabled={isSubmitting}
                          className="mt-3 btn-salvar"
                          type="submit"
                        >
                          Salvar <MdSave color="#FFF" />
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};
