import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Col, Container, Row, Button } from "reactstrap";
import { Layout } from "../../componentes/Layout";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from "moment";
import { IoIosCopy } from "react-icons/io";
import {
  BoletoResponseModel,
} from "../../context/services/api/models";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const Pagamento = () => {
  const location = useLocation();
  const history = useHistory();

  if (!location.state.boleto) {
    toast.error("Houve um erro ao carregar as informações de pagamento.");
  } 

  return (
      <Layout>
          <div className="pagina-container">
            <div className="headerbar p-3 mb-3">
              <Container>
                <Row>
                  <Link to="/meus-boletos" className="btn-back">
                    <IoMdArrowRoundBack size={16} />
                    Voltar
                  </Link>
                </Row>
              </Container>
            </div>
            <Container className="pagamento-container">
              <Row className="mb-4 m-1">
                <h3 className="m-0">Informações da pendência</h3>
                <Col className="pagamento-info-container">
                  <p>
                  Referência:
                    {` Mensalidade Sócio - ${new Date(location.state.boleto.dataReferencia)
                                    .toLocaleString("pt-BR", { month: "long"})
                                    }`}
                  </p>
                  <p>
                    Vencimento: {Moment(location.state.boleto.vencimento).format("DD/MM/YYYY")}
                  </p>
                  <p>
                    Valor: {`R$ ${location.state.boleto.vlCobrado.toLocaleString(
                              "pt-BR",
                              { minimumFractionDigits: 2 }
                            )}`}
                  </p>
                </Col>
              </Row>
              <Row className="mb-4 m-1">
                <h3 className="pagamento-collapse">Pagar com boleto</h3>
                <Col className="pagamento-pagarcomboleto-container">
                  <Container>
                    <Row>
                        <Button
                          className="mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: "/imprimir-boleto",
                              state: { boleto: location.state.boleto }
                            })
                          }}
                        >
                            Imprimir boleto
                        </Button>
                    </Row>
                    <Row>
                      <Button
                        className="mb-3"
                      >
                        <CopyToClipboard text={location.state.boleto.codigoBarras}
                          onCopy={() => toast.success("Código copiado com sucesso!")}>
                          <span>Copiar código de barras{" "}</span>
                        </CopyToClipboard>
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        className="mb-3"
                      >
                        <CopyToClipboard text={location.state.boleto.linhaDigitavel}
                          onCopy={() => toast.success("Código copiado com sucesso!")}>
                          <span>Copiar linha digitavel{" "}</span>
                        </CopyToClipboard>
                      </Button>
                    </Row>
                    </Container>
                </Col>
              </Row>
            </Container>
          </div>
    </Layout>
  );
};