import { createContext } from "react";
import { AutenticarModel } from "../../models";

const UsuarioContext = createContext<IUsuarioState>(null);

export interface IUsuarioState {
  nome: string;
  autenticarUsuarioAsync?: (autenticarModel: AutenticarModel) => void;
  logoffUsuarioLogado?: () => void;
  isUsuarioAutenticado: boolean;
  titulo: number;
}

export default UsuarioContext;