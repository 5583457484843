import React, { Component } from "react";
import {
  RiPassportFill,
  RiContactsFill,
  RiLockPasswordFill,
  RiContactsBook2Fill,
  RiShieldCheckFill,
  RiArtboardFill
} from "react-icons/ri";
import { FaAddressBook } from "react-icons/fa";
import { Col, Container, Row } from "reactstrap";
import boleto from "../../assets/boleto.svg";
import { Link } from "react-router-dom";
import { Layout } from "../../componentes/Layout";
import FundoSocio from '../assets/FundoSocio.png'

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <Layout>
        <div className="home-container" style={{ paddingTop: 120}}>
          <Container>
            <Row>
              <Col>
                <div className="containerhome">
                  <Link to="/mural">
                    <div className="items">
                      <div className="icon-wrapper">
                        <RiArtboardFill size={66} />
                      </div>
                      <div className="link-name">
                        <p>Mural dos sócios</p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/meus-boletos">
                    <div className="items">
                      <div className="icon-wrapper">
                        <img src={boleto} className="boleto-icon" />
                      </div>
                      <div className="link-name">
                        <p>Meus boletos</p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/meu-titulo">
                    <div className="items">
                      <div className="icon-wrapper">
                        <RiPassportFill size={66} />
                      </div>
                      <div className="link-name">
                        <p>Meu Título</p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/editar-endereço">
                    <div className="items">
                      <div className="icon-wrapper">
                        <FaAddressBook size={66} />
                      </div>
                      <div className="link-name">
                        <p>Alterar endereço</p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/alterar-dados">
                    <div className="items">
                      <div className="icon-wrapper">
                        <RiContactsBook2Fill size={66} />
                      </div>
                      <div className="link-name">
                        <p>Alterar dados</p>
                      </div>
                    </div>
                  </Link>
                  <Link to="/protecao-de-dados">
                    <div className="items">
                      <div className="icon-wrapper">
                        <RiShieldCheckFill size={66} />
                      </div>
                      <div className="link-name">
                        <p>Proteção de Dados</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}
