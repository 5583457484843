import axios from "axios";

import {
  AutenticarModel,
  ContatoModel,
  SolicitacaoLoginModel,
  UsuarioModel,
  ValidarIdentidadeModel,
  AlterarSenhaModel,
  EditarEnderecoModel,
  LogModel
} from "../../../models";

import {
  BoletoResponseModel,
  MuralResponseModel,
  ParametroResponseModel,
  SocioResponseModel,
  TituloResponseModel,
  UsuarioApiResponseModel,
  UsuarioAuthApiResponseModel,
  UsuarioResponseModel,
  ViaCepResponseModel
} from "./models";

var currentRequests = [];
var runningRequests = 0;
var maxConcurrentRequests = 1;

const callFunction = (fnToCall, ...args) => {
  return new Promise((resolve, reject) => {
    currentRequests.push({
      resolve,
      reject,
      fnToCall,
      args,
    });
    tryNext();
  });
};

const tryNext = () => {
  if (!currentRequests.length) {
    return;
  } else if (runningRequests < maxConcurrentRequests) {
    let { resolve, reject, fnToCall, args } = currentRequests.shift();
    runningRequests++;
    let req = fnToCall(...args);
    req
      .then((res) => resolve(res))
      .catch((err) => reject(err))
      .finally(() => {
        runningRequests--;
        tryNext();
      });
  }
};

let configuration = {};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  configuration = {
    baseURL: "http://localhost:5110",
  };
} else {
  configuration = {
    baseURL: process.env.PUBLIC_URL,
  };
}

export default class ApiService {
  private static apiServiceAxios = axios.create(configuration);

  static loginData = {
    token: undefined,
    refreshToken: undefined,
    expirationTime: undefined,
  };

  static setTokenUsuarioAutenticado(
    token: string,
    refreshToken: string,
    expirationTime: number
  ) {
    this.apiServiceAxios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    this.loginData.token = token;
    this.loginData.refreshToken = refreshToken;
    this.loginData.expirationTime = expirationTime;

    localStorage.setItem("usuario_autenticado", JSON.stringify(this.loginData));
  }

  static getRequestAsync = async <T>(
    path: string,
    data: any = undefined,
    autenticar: boolean = false
  ): Promise<any> => {
    if (autenticar) {
      await ApiService.atualizarUsuarioLogadoAsync();
    }
    const response = await ApiService.apiServiceAxios.get<T>(path, {
      params: data,
    });
    return response;
  };

  static postRequestAsync = async <T>(
    path: string,
    data: any,
    autenticar: boolean = false
  ): Promise<any> => {
    if (autenticar) {
      await ApiService.atualizarUsuarioLogadoAsync();
    }

    return await ApiService.apiServiceAxios.post<T>(path, data);
  };

  static putRequestAsync = async <T>(
    path: string,
    data: any,
    autenticar: boolean = false
  ): Promise<any> => {
    if (autenticar) {
      await ApiService.atualizarUsuarioLogadoAsync();
    }

    return await ApiService.apiServiceAxios.put<T>(path, data);
  };

  static async atualizarUsuarioLogadoAsync(): Promise<void> {
    await callFunction(async () => {
      let usuarioAutenticadoLocalJson = localStorage.getItem(
        "usuario_autenticado"
      );

      if (usuarioAutenticadoLocalJson) {
        const usuarioAutenticadoLocal = JSON.parse(usuarioAutenticadoLocalJson);
        const { token, refreshToken, expirationTime } = usuarioAutenticadoLocal;

        let dtAgora = new Date();
        dtAgora.setSeconds(dtAgora.getSeconds() + 10);
        let dtValidar = new Date(expirationTime);
        if (dtAgora < dtValidar) {
          return;
        }

        var response = await this.postRequestAsync<UsuarioAuthApiResponseModel>(
          "/Autenticacao/refreshToken",
          {
            token: token,
            refreshToken: refreshToken,
          }
        );

        const data = response.data;

        this.setTokenUsuarioAutenticado(
          data.token,
          data.refreshToken,
          data.expirationTime
        );
      }
    });
  }

  static async autenticarUsuarioAsync (autenticarModel: AutenticarModel) {
    var response = await this.postRequestAsync<UsuarioApiResponseModel>(
      "/Autenticacao",
      {
        login: autenticarModel.login,
        senha: autenticarModel.senha,
      }
    );

    return response.data;
  }

  static async verificarCpfValido(cpf: string) {
    var response = await this.getRequestAsync(
      `/usuario/verificarCpfValido?dsCpf=${cpf}`
    );
    return response.data;
  }

  static async verificarEmailCadastrado(email: string) {
    var response = await this.getRequestAsync(
      `/usuario/emailCadastrado?dsEmail=${email}`, null, false
    );

    return response.data;
  }

  static async validarIdentidadeAsync(identidade: ValidarIdentidadeModel) {
    var response = await this.postRequestAsync(
      `/Socio/identidade`, identidade, false
    );

    return response.data;
  }

  static async inserirSolicitacaoLogin(novoUsuario: SolicitacaoLoginModel) {
    let formData = new FormData();
    formData.append('titulo', novoUsuario.nrTitulo);
    formData.append('cpf', novoUsuario.cpf);
    formData.append('nome', novoUsuario.nomeCompleto);
    formData.append('email', novoUsuario.email);
    formData.append('senha', novoUsuario.senha);
    formData.append('arquivo', novoUsuario.imagem);

    var response = await this.postRequestAsync(
      `/solicitacaologin`, 
      formData,
      false
    );

    return response.data;
  }

  static async getTituloESocios() {
    let response = await this.getRequestAsync(
      `/Titulo/tituloesocios/`, null, true
    );

    return response.data;
  }

  static async getTitulo() {
    let response = await this.getRequestAsync<TituloResponseModel>(
      `/Titulo`, null, true
    );

    return response.data;
  }

  static async getPendenciasFinanceiras() {
    let response = await this.getRequestAsync<BoletoResponseModel[]>(
      `/Financeiro`, null, true
    );

    return response.data;
  }

  static async inserirSolicitacaoContato(solicitacao: ContatoModel) {
    let response = await this.postRequestAsync(
      `/SolicitacaoContato`, solicitacao, true
    );

    return response.data;
  }

  static async getSocioAsync()
  : Promise<SocioResponseModel> 
  {
    let response = await this.getRequestAsync(
      `/Socio/titular`, null, true
    );

    return response.data;
  }

  static async getUsuarioAsync()
  : Promise<UsuarioResponseModel> 
  {
    let response = await this.getRequestAsync(
      `/Usuario`, null, true
    );

    return response.data;
  }

  static async postEmailAlterarSenhaAsync(email: string)
  : Promise<string> 
  {
    let response = await this.postRequestAsync(
      `/Email/esquecisenha?email=${email}`, null
    );

    return response.data;
  }

  static async putAlterarSenhaAsync(model: AlterarSenhaModel)
  : Promise<void> 
  {
    let response = await this.putRequestAsync(
      `/Usuario/alterarsenha/`, model
    );

    return response.data;
  }


  static async postSolicitacaoEndereco(endereco: EditarEnderecoModel): Promise<void> {
    let formData = new FormData();
    formData.append('cep', endereco.cep);
    formData.append('endereco', endereco.logradouro);
    formData.append('nrResidencia', endereco.numero);
    formData.append('complemento', endereco.complemento);
    formData.append('bairro', endereco.bairro);
    formData.append('cidade', endereco.cidade);
    formData.append('estado', endereco.estado);
    formData.append('arquivo', endereco.comprovante);
    formData.append('condominio', JSON.stringify(endereco.checkCondominio));
    formData.append('residencial', JSON.stringify(endereco.checkEnderecoResidencial));
    formData.append('faturamento', JSON.stringify(endereco.checkEnderecoFaturamento));

    var response = await this.postRequestAsync(
      `/solicitacaoendereco`,
      formData,
      true
    );

    return response.data;
  }

  static async getViaCep(cep: string) : Promise<ViaCepResponseModel> {
    var response = await axios.get(`https://viacep.com.br/ws/${cep}/json`, {
      transformRequest: (data, headers) => {
        delete headers.common;
        return data;
      }
    });

    return response.data;
  }

  static async postLogAcaoSocio(dados: LogModel) {
    var response = await this.postRequestAsync(
      `/usuario/Log`,
      dados,
      true
    );
    
    return response.data;
  }

  static async getInformacoesMural() : Promise<MuralResponseModel[]> {
    var response = await this.getRequestAsync<MuralResponseModel[]>(
      `/MuralSocios`,
      null,
      true
    );
    
    return response.data;
  }

  static async getParametro(parametro: string) : Promise<ParametroResponseModel> {
    console.log("cheguei aqui")
    var response = await this.getRequestAsync<ParametroResponseModel>(
      `/Parametro/${parametro}`,
      null,
      true
    );
    
    return response.data;
  }
}
