import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { AutenticarModel } from "../../models";
import lendariologin from "../../assets/lendariologin.png";
import UsuarioContext from "../../context/usuario/usuarioContext";
import { toast } from "react-toastify";
import { Layout } from "../../componentes/Layout";
import LoadingBar from 'react-top-loading-bar';

const FormSchema = Yup.object().shape({
  login: Yup.string().required("O campo login é obrigatório."),
  senha: Yup.string().required("O campo senha é obrigatório."),
});

export const MinhaConta = (props) => {
  const history = useHistory();
  const loadingBar = useRef(null);
  const { autenticarUsuarioAsync, isUsuarioAutenticado } =
    useContext(UsuarioContext);

  const onSubmitLoginUser = async (model: AutenticarModel) => {
    loadingBar.current.continuousStart();
    await autenticarUsuarioAsync(model);
  };

  if (isUsuarioAutenticado) {
    history.push("home");
  }

  return (

    <Layout hideMenuToggler={true}>
      <LoadingBar
                height={7}
                color='#ff9138'
                ref={loadingBar}
                />
      <div className="bg-secondary-color minha-conta-login-wrapper">
        <h1 className="h3 mb-3 font-weight-normal title text-center title-light">
          Área do Sócio
        </h1>
        <div className="minha-conta-login-container">
          <Formik
            initialValues={{
              login: "",
              senha: "",
            }}
            validationSchema={FormSchema}
            onSubmit={async (model: AutenticarModel, { setSubmitting }) => {
              setSubmitting(false);
              try {
                await onSubmitLoginUser(model);
              } catch (error) {
                toast.error(error.message);
              }
            }}
          >
            {(formik) => {
              const { isSubmitting, errors, setFieldValue } = formik;

              return (
                <Form className="form-signin">
                  <FormGroup>
                    <Label for="inputLogin" className="sr-only">
                      Login
                    </Label>
                    <Field
                      type="text"
                      name="login"
                      id="inputLogin"
                      placeholder="Email"
                      className="form-control input-light-ec mb-2"
                    />

                    <ErrorMessage
                      name="login"
                      component="div"
                      className="errormessage"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="inputPassword" className="sr-only">
                      Senha
                    </Label>
                    <Field
                      type="password"
                      name="senha"
                      id="inputPassword"
                      placeholder="Senha"
                      className="form-control  input-light-ec"
                    />

                    <ErrorMessage
                      name="senha"
                      component="div"
                      className="errormessage"
                    />
                  </FormGroup>
                 
                  <Button
                    disabled={isSubmitting}
                    className="btn btn-lg btn-primary btn-block mt-3"
                    type="submit"
                  >
                    Entrar
                  </Button>
                  <Link to="/cadastro" className="mt-2">
                    <p>Ainda não tem uma conta? Cadastre-se</p>
                  </Link>
                  <Link to="/esqueci-minha-senha">
                    <p>Esqueci a minha senha</p>
                  </Link>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div
          style={{ backgroundImage: `url(${lendariologin})` }}
          className="minha-conta-bg-image"
        ></div>
      </div>
    </Layout>
  );
};
