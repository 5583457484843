import { ErrorMessage, Field, Form, Formik, isEmptyArray } from "formik";
import React, { Component, useState, useCallback, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import lendario from "../../assets/lendario.jpg";
import { toast } from "react-toastify";
import ApiService from "../../context/services/api/apiService";
import { Layout } from "../../componentes/Layout";
import { AlterarSenhaModel } from "../../models";

const FormValidarSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Informe o seu e-mail.")
    .email("Informe um e-mail válido.")
    .max(50, "Informe um e-mail válido."),
  codigoVerificacao: Yup.string().required("Informe seu código de verificação"),
});

const FormSenhaSchema = Yup.object().shape({
  senha: Yup.string()
    .required("Informe uma senha com ao menos 5 caracteres.")
    .min(5, "Informe uma senha com ao menos 5 caracteres.")
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])/,
      "A senha precisa conter pelo menos um número e uma letra."
    ),
  confirmarSenha: Yup.string()
    .required("Informe uma senha com ao menos 5 caracteres.")
    .min(5, "Informe uma senha com ao menos 5 caracteres.")
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])/,
      "A senha precisa conter pelo menos um número e uma letra."
    )
    .test("senhas-match", "As senhas precisam ser iguais", function (value) {
      return this.parent.senha === value;
    }),
});

export const EsqueciMinhaSenha = () => {
  const [isValidarCodigo, setIsValidarCodigo] = useState(false);
  const [isTrocarSenha, setIsTrocarSenha] = useState(false);
  const [codigoRecebido, setCodigoRecebido] = useState("");
  const [emailUsuario, setEmailUsuario] = useState("");
  let history = useHistory();

  const onClickEnviarEmail = async (email: string) => {
    try {
      let resp: string = await ApiService.postEmailAlterarSenhaAsync(email);
      setCodigoRecebido(resp);
      setIsValidarCodigo(true);
      setEmailUsuario(email);
    } catch (error) {
      toast.error("Houve um erro ao enviar o e-mail.");
    }
  };

  const onSubmitTrocarSenha = async (senha: string) => {
    try {
      let obj: AlterarSenhaModel = {
        email: emailUsuario,
        novaSenha: senha,
      };

      await ApiService.putAlterarSenhaAsync(obj);
    } catch {
      toast.error("Houve um erro ao alterar sua senha.");
    }
    
  };

  return (
    <Layout hideMenuToggler={true}>
      <div className="bg-secondary-color esqueci-senha-wrapper">
        <h1 className="h3 mb-3 font-weight-normal title text-center title-light">
          Recuperar Conta
        </h1>
        <div className="esqueci-senha-container">
          {!isTrocarSenha ? (
            <Formik
              initialValues={{
                email: "",
                codigoVerificacao: "",
              }}
              validationSchema={FormValidarSchema}
              onSubmit={async (model, { setSubmitting }) => {
                setSubmitting(false);
                if (codigoRecebido.toString() === model.codigoVerificacao) {
                  setIsTrocarSenha(true);
                  setCodigoRecebido("");
                  toast.success("Código validado com sucesso!");
                } else {
                  toast.error("Código incorreto.");
                }
              }}
            >
              {(formik) => {
                const { errors, validateForm, isSubmitting, values } = formik;

                return (
                  <Form className="form-esqueci-senha">
                    {!isValidarCodigo ? (
                      <>
                        <p className="p mb-3 font-weight-normal title text-center title-light w100">
                          Digite seu e-mail abaixo e nós iremos te enviar um
                          código de verificação
                        </p>
                        <FormGroup>
                          <Label for="inputEmail" className="sr-only">
                            Email cadastrado
                          </Label>
                          <Field
                            type="text"
                            name="email"
                            id="inputEmail"
                            placeholder="Email"
                            className="form-control input-light-ec mb-2"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <Button
                          className="btn btn-lg btn-primary btn-block mt-3"
                          onClick={() => {
                            validateForm().then(() => {
                              onClickEnviarEmail(values.email);
                            });
                          }}
                        >
                          Enviar e-mail
                        </Button>
                      </>
                    ) : (
                      <>
                        <p className="p mb-3 w100">
                          Digite abaixo o código de verificação enviado para seu
                          e-mail. Verifique sua caixa de spam
                        </p>
                        <FormGroup>
                          <Label
                            for="inputCodigoVerificacao"
                            className="sr-only"
                          >
                            Código de verificação
                          </Label>
                          <Field
                            type="text"
                            name="codigoVerificacao"
                            id="inputCodigoVerificacao"
                            placeholder="Código"
                            className="form-control input-light-ec mb-2"
                          />
                          <ErrorMessage
                            name="codigoVerificacao"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <Button
                          disabled={isSubmitting}
                          className="btn btn-lg btn-primary btn-block mt-2"
                          type="submit"
                        >
                          Verificar código
                        </Button>
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                senha: "",
                confirmarSenha: "",
              }}
              validationSchema={FormSenhaSchema}
              onSubmit={async (model, { setSubmitting }) => {
                setSubmitting(false);
                try {
                  onSubmitTrocarSenha(model.senha);
                  toast.success("Senha modificada com sucesso!");
                  history.push("/");
                } catch {
                  toast.error("Houve um erro ao enviar o email.");
                }
              }}
            >
              {(formik) => {
                const { isSubmitting, values } = formik;

                return (
                  <Form className="form-trocar-senha">
                    <p className="p mb-3 text-center w100">
                      Crie uma nova senha com no mínimo 5 caracteres (letras e números)
                    </p>
                    <FormGroup>
                      <Label for="inputSenha" className="sr-only">
                        Senha
                      </Label>
                      <Field
                        type="password"
                        name="senha"
                        id="inputSenha"
                        placeholder="Senha"
                        className="form-control input-light-ec mb-2"
                      />
                      <ErrorMessage
                        name="senha"
                        component="div"
                        className="errormessage"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="inputConfirmarSenha" className="sr-only">
                        Confirmar senha
                      </Label>
                      <Field
                        type="password"
                        name="confirmarSenha"
                        id="inputConfirmarSenha"
                        placeholder="Confirmar senha"
                        className="form-control input-light-ec mb-2"
                      />
                      <ErrorMessage
                        name="confirmarSenha"
                        component="div"
                        className="errormessage"
                      />
                    </FormGroup>
                    <Button
                      disabled={isSubmitting}
                      className="btn btn-lg btn-primary btn-block mt-2"
                      type="submit"
                    >
                      Alterar Senha
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
        <div
          style={{ backgroundImage: `url(${lendario})` }}
          className="esqueci-senha-bg-image"
        ></div>
      </div>
    </Layout>
  );
};
