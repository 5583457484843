import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import ApiService from "../../context/services/api/apiService";
import UsuarioContext from "../../context/usuario/usuarioContext";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import {
  BoletoResponseModel,
  TituloResponseModel,
} from "../../context/services/api/models";
import { toast } from "react-toastify";
import Moment from "moment";
import { Layout } from "../../componentes/Layout";
import LoadingBar from 'react-top-loading-bar';

export const MeusBoletos = () => {
  const history = useHistory();
  const { usuario } = useContext(UsuarioContext);
  const loadingBar = useRef(null);

  const [tituloDados, setTituloDados] = useState<TituloResponseModel>([]);
  const [boletos, setBoletos] = useState<BoletoResponseModel[]>([]);
  const [patrimonial, setPatrimonial] = useState(false);

  useEffect(async () => {
    try {
      loadingBar.current.continuousStart()
      let respTitulo = await ApiService.getTitulo();
      setTituloDados(respTitulo);
      loadingBar.current.complete();
    } catch (error) {
      toast.error(
        "Houve um problema ao tentar consultar informações do título."
      );
    }

    try {
      let respBoletos: BoletoResponseModel[] =
        await ApiService.getPendenciasFinanceiras();

      if (respBoletos.length > 0) {
        respBoletos.sort((a, b) =>
          Moment.utc(a.vencimento).diff(Moment.utc(b.vencimento))
        );
        setBoletos(respBoletos);
        setPatrimonial(respBoletos[0].patrimonialOuUsuario.trim().toUpperCase() === 'P');
      }
      
    } catch (error) {
      toast.error(
        "Houve um problema ao tentar consultar as pendências financeiras."
      );
    }
  }, []);

  const OnClickPagar = (e, obj) => {
    e.preventDefault();
    history.push({
      pathname: "/pagamento",
      state: { boleto: obj },
    });
  };

  const OnClickPagarPatrimonial = () => {
    if (boletos.length > 1) {
       toast.error("Ainda não é possível realizar o pagamento de mais de um boleto.");
    } else {
      history.push({
        pathname: "/pagamento",
        state: { boleto: boletos[0] },
      });
    }
  }

  return (
    <Layout>
      <LoadingBar
                height={4}
                color='#ff9138'
                ref={loadingBar}
                />
      <div className="pagina-container">
        <div className="headerbar p-3 mb-3">
          <Container>
            <Row>
              <Link to="/home" className="btn-back">
                <IoMdArrowRoundBack size={16} />
                Voltar
              </Link>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <Col>
              <h2>Meu título</h2>
              <div className="container-table-modelo container-table-boleto-titular">
                <div className="wrap-table-100">
                  <div className="table-boleto-titular">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1">Nome do titular</th>
                          <th className="column2">Num. do título</th>
                          <th className="column3">Tipo do título</th>
                          <th className="column4">Familiar/Individual</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="column1">{usuario.nome}</td>
                          <td className="column2">{tituloDados.id}</td>
                          <td className="column3">{tituloDados.tipoTitulo}</td>
                          <td className="column4">{tituloDados.categoria}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h2 className="mt-3">Parcelas pendentes de pagamento</h2>
              <div className="container-table-modelo container-table-boleto-parcelas">
                <div className="wrap-table-100">
                  <div className="table-boleto-titular">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1">Situação</th>
                          <th className="column2">Vencimento</th>
                          <th className="column3">Valor</th>
                          <th className="column4">Juros/Multa</th>
                          <th className="column5">Referente</th>
                          {!patrimonial && (<th className="column6"></th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {boletos.map((item: BoletoResponseModel) => (
                          <tr key={item.id}>
                            <td className="column1">{item.status}</td>
                            <td className="column2">
                              {Moment(new Date(item.vencimento)).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td className="column3">{`R$ ${item.vlCobrado.toLocaleString(
                              "pt-BR",
                              { minimumFractionDigits: 2 }
                            )}`}</td>
                            <td className="column4">{`R$ ${item.vlJuros.toLocaleString(
                              "pt-BR",
                              { minimumFractionDigits: 2 }
                            )}`}</td>
                            <td className="column5">{`Mensalidade Sócio - ${new Date(
                              item.dataReferencia
                            ).toLocaleString("pt-BR", {
                              month: "short",
                            })}`}</td>
                            {!patrimonial && (
                            <td className="column6">
                                <Button
                                className="align-self-center"
                                onClick={(e) => OnClickPagar(e, item)}
                                disabled={patrimonial}
                              >
                                Realizar pagamento{" "}
                                <RiMoneyDollarCircleFill />
                              </Button>  
                            </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {patrimonial && (
                <Container className="mt-3">
                  <Row>
                    <Col>
                    <Button
                          className="w-100"
                          onClick={() => OnClickPagarPatrimonial()}
                        >
                        Realizar pagamento{" "}
                        <RiMoneyDollarCircleFill />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        * Sócios patrimoniais devem realizar o pagamento completo.
                        Se houver mais de uma pendência financeira, é necessário pagar pela secretaria.
                      </p>                    
                    </Col>
                  </Row>
                </Container>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};
