import { AUTENTICATE_USER } from "../types";

const UsuarioReducer = (state, action) => {
  const { type, payload } = action;
  switch (action.type) {
    default:
      return {
        ...state,
        ...payload,
      };
  }
};

export default UsuarioReducer;
