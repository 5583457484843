import React, { useRef, useEffect, useState } from "react";
import ApiService from "../../context/services/api/apiService";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Col, Container, Row } from "reactstrap";
import { Layout } from "../../componentes/Layout";
import LoadingBar from 'react-top-loading-bar';
import { MuralResponseModel } from "../../context/services/api/models";
import ReactHtmlParser from 'react-html-parser';

export const Mural = () => {
  const loadingBar = useRef(null);
  const [ muralDados, setMuralDados ] = useState<MuralResponseModel[]>([]);

  useEffect(async () => {
    loadingBar.current.continuousStart();
    
    let dados: MuralResponseModel[] = await ApiService.getInformacoesMural();
    setMuralDados(dados.sort((a, b) => a.posicao - b.posicao));

    loadingBar.current.complete();
  }, []);

  return (
      <Layout>
        <LoadingBar
                height={4}
                color='#ff9138'
                ref={loadingBar}
                />
          <div className="pagina-container">
            <div className="headerbar p-3 mb-3">
              <Container>
                <Row>
                  <Link to="/home" className="btn-back">
                    <IoMdArrowRoundBack size={16} />
                    Voltar
                  </Link>
                </Row>
              </Container>
            </div>
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center mt-3 mb-5">
                    Mural de informações, notícias e avisos
                  </h1>
                </Col>
              </Row>
              <Row>
                <div style={{minHeight: "100vh"}}>
                  {muralDados.map((item: MuralResponseModel) => (
                    <Container className="mural-container-base text-monospace mb-5">
                      <h3>
                        {item.titulo}
                      </h3>
                      <h5 className="mb-3 border-bottom pb-2">
                        {item.subtitulo}
                      </h5>
                      <div>
                        { ReactHtmlParser (item.corpoHtml) }
                      </div>
                    </Container>
                  ))}
                </div>
              </Row>
              </Container>
          </div>
    </Layout>
  );
};
