import React, { Component, useContext, useEffect } from "react";
import { Route, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import ApiContext from "./context/apiContext";
import RouteConfig from "./routeConfig";
import { Redirect } from "react-router-dom";
import "./estilo/custom.scss";
import UsuarioContext from "./context/usuario/usuarioContext";

const PrivateRoute = (props) => {
  const { isUsuarioAutenticado } = useContext(UsuarioContext);
  const { entrar } = RouteConfig;
  const { component: Component, path, location, ...rest } = props;
  return (
    <Route
      path={path}
      render={(props) => {
        if (isUsuarioAutenticado) {
          return <Component {...props} />;
        }

        return <Redirect to={{ pathname: entrar.path }} from={location} />;
      }}
      {...rest}
    />
  );
};

const StartComponent = () => {
  const { carregarDadosUsuario } = useContext(UsuarioContext);

  useEffect(() => {
    carregarDadosUsuario();
  }, []);

  var {
    entrar,
    home,
    meuTitulo,
    cadastro,
    meusBoletos,
    alterarDados,
    alterarSenha,
    imprimirBoleto,
    protecaoDeDados,
    esqueciMinhaSenha,
    pagamento,
    editarEndereco,
    mural
  } = RouteConfig;

  return (
    <ApiContext>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact {...entrar} />
        <Route {...cadastro} />
        <Route {...esqueciMinhaSenha} />
        <PrivateRoute {...home} />
        <PrivateRoute {...meuTitulo} />
        <PrivateRoute {...meusBoletos} />
        <PrivateRoute {...alterarDados} />
        <PrivateRoute {...alterarSenha} />
        <PrivateRoute {...imprimirBoleto} />
        <PrivateRoute {...protecaoDeDados} />
        <PrivateRoute {...pagamento} />
        <PrivateRoute {...editarEndereco} />
        <PrivateRoute {...mural} />
      </Switch>
    </ApiContext>
  );
};
export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <ApiContext>
        <StartComponent />
      </ApiContext>
    );
  }
}
