import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { SocialIcon } from "react-social-icons";
import wave from "../assets/wave.svg";
import wave2 from "../assets/wave2.svg";

const Footer = (props) => {
  const [endereco, setEndereco] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {}, []);

  return (
    <Container fluid className="footer-container pt-3">
      {/* <div className="ocean">
        <div className="wave" style={{ backgroundImage: `url(${wave})` }}></div>
        <div
          className="wave"
          style={{ backgroundImage: `url(${wave2})` }}
        ></div>
      </div> */}
      <svg
        class="editorial"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
          <use xlinkHref="#gentle-wave" x="50" y="3" fill="#3461c1" />
          <use xlinkHref="#gentle-wave" x="50" y="6" fill="#013e7f" />
        </g>
      </svg>

      <Container fluid className="footer-dados">
        <Container>
          <Row>
            <Col>
              <div className="float-left align-mobile"></div>
            </Col>
            <Col>
              <div className="float-right align-mobile"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-center mt-2 creditos">
                Todos os direitos reservados. Desenvolvido por PWI
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default Footer;
