import React, { useState } from "react";
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.scss";
import Logo from "../logotipo/Logo";
import { NavMenuLinks } from "./NavMenuLinks";
import useIsMobile from "../../utils/detectMobile";

interface Props {
  hideMenuToggler?: boolean;
  children?: any;
}

export const NavMenu = (props: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [ismobile, setIsmobile] = useState(useIsMobile());

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <header className="header-container">
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3 bg-nav pt-1 pb-1 px-5"
        dark
      >
        <NavbarBrand
          tag={Link}
          to="/"
          onClick={() => ismobile && collapsed && toggleNavbar()}
        >
          <Logo />
        </NavbarBrand>
        {!props.hideMenuToggler && (
          <NavbarToggler
            onClick={() => ismobile && toggleNavbar()}
            className="mr-2"
          />
        )}
        <div className="desktopmenu d-none d-sm-flex">
          <NavMenuLinks />
        </div>
      </Navbar>
      <div
        id="myNav"
        className={
          collapsed
            ? "mobilemenu menumobileopen"
            : "mobilemenu menumobilehidden"
        }
      >
        <a
          href="javascript:void(0)"
          className="closebtn"
          id="closeNav"
          onClick={() => ismobile && toggleNavbar()}
        >
          &times;
        </a>
        <div className="overlay-content">
          <NavMenuLinks
            closeMenuAfterclickpage={() => ismobile && toggleNavbar()}
          />
        </div>
      </div>
    </header>
  );
};
