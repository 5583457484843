export const Cadastro_ProximosPassos =
<>
    <p>Sua solicitação para ter acesso ao portal foi enviada.</p>
    <p><b>Seu acesso ao portal será liberado após análise da secretaria de sócios; você receberá um e-mail com a resposta.</b></p>
    <p>Por gentileza verificar sua caixa de SPAM caso o e-mail não esteja em sua caixa de entrada.</p>
    <p>Em caso de dúvida, entre em contato com a secretaria de sócios:</p>
    <p>17-3279-3500, Ramal: 517 ou 518</p>
</>

export const EditarEndereco_ProximosPassos =
<>
    <p>Sua solicitação para alterar dados de endereço enviada.</p>
    <p>Em breve você receberá um e-mail com a resposta de nossa análise.</p>
    <p>Por gentileza verificar sua caixa de SPAM caso o e-mail não esteja em sua caixa de entrada.</p>
    <p>Em caso de dúvida, entre em contato com a secretaria de sócios:</p>
    <p>17-3279-3500, Ramal: 517</p>
</>

export const ProtecaoDeDados_Conteudo =
<>
    <p>
        {" "} Em julho de 2020, o Clube instituiu o Programa Permanente de Boas Práticas em Proteção de Dados com a finalidade de 
        conformar-se à Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018). O Departamento Jurídico do Clube foi constituído
        como encarregado pelo tratamento de dados pessoais e pode ser contactado pelo e-mail deptojuridico@termas.com.br.
    </p>
    <a target="_blank" href="https://www.termas.com.br/informacoes/protecao-de-dados-pessoais/politica-de-privacidade-e-protecao-de-dados">Saiba mais</a>
</>