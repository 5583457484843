import React, { useEffect, useContext, useState, useRef } from "react";
import { ErrorMessage, Field, Form, Formik, isEmptyArray } from "formik";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Layout } from "../../componentes/Layout";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { AlterarSenhaModel } from "../../models";
import { MdSave } from "react-icons/md";
import { toast } from "react-toastify";
import ApiService from "../../context/services/api/apiService";
import UsuarioContext from "../../context/usuario/usuarioContext";
import { SocioResponseModel, UsuarioApiResponseModel, UsuarioResponseModel } from "../../context/services/api/models";
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
  } from "reactstrap";
import LoadingBar from 'react-top-loading-bar';

const FormSchema = Yup.object().shape({
    senha: Yup.string().trim().lowercase()
      .required("Informe uma senha com ao menos 5 caracteres.")
      .min(5, "Informe uma senha com ao menos 5 caracteres (número e letra).")
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])/,
        "A senha precisa conter pelo menos um número e uma letra."
      ),
    confirmarSenha: Yup.string().trim()
      .required("Informe uma senha com ao menos 5 caracteres.")
      .min(5, "Informe uma senha com ao menos 5 caracteres (número e letra).")
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])/,
        "A senha precisa conter pelo menos um número e uma letra."
      )
      .test("senhas-match", "As senhas precisam ser iguais", function (value) {
        return this.parent.senha === value;
      })
  });

export const AlterarSenha = () => {
    let history = useHistory();
    const [ titular, setTitular ] = useState<UsuarioResponseModel>([]);
    const loadingBar = useRef(null);

    useEffect(async () => {
        loadingBar.current.continuousStart();
        let resp: UsuarioResponseModel = await ApiService.getUsuarioAsync();
        setTitular(resp);
        loadingBar.current.complete();
    }, []);

    const onSubmitTrocarSenha = async (senha: string) => {
        let obj: AlterarSenhaModel = {
            email: titular.email,
            novaSenha: senha 
        };

        await ApiService.putAlterarSenhaAsync(obj);
    }

    return (
      <Layout>
          <LoadingBar
                height={4}
                color='#ff9138'
                ref={loadingBar}
                />
          <div className="pagina-container">
            <div className="headerbar p-3 mb-3">
              <Container>
                <Row>
                  <Link to="/home" className="btn-back">
                    <IoMdArrowRoundBack size={16} />
                    Voltar
                  </Link>
                </Row>
              </Container>
            </div>
            <Container>
                <Row>
                    <Col>
                        <h1 className="h3 mb-3 title title-light">
                        Dados da minha conta
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            nome: titular.nome,
                            email: titular.email,
                            cpf: titular.cpf,
                            senha: "",
                            confirmarSenha: ""
                        }}
                        validationSchema={FormSchema}
                        onSubmit={async (model, { setSubmitting }) => {
                            setSubmitting(false);
                            try {
                                onSubmitTrocarSenha(model.senha);
                                toast.success("Senha modificada com sucesso!");
                                history.push("/");
                            } catch {
                            toast.error("Houve um erro ao enviar o email.");
                            }
                        }}
                        >
                        {(formik) => {
                            const {
                                isSubmitting,
                                values
                            } = formik;

                            return (
                            <Form className="form-alterar-senha">
                                <FormGroup>
                                    <Label for="inputNome" className="sr-only">
                                    Nome
                                    </Label>
                                    <Field
                                        type="text"
                                        name="nome"
                                        id="inputNome"
                                        placeholder="Nome"
                                        className="form-control input-light-ec mb-2"
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="inputEmail" className="sr-only">
                                    Email
                                    </Label>
                                    <Field
                                        type="text"
                                        name="email"
                                        id="inputEmail"
                                        placeholder="Email"
                                        className="form-control input-light-ec mb-2"
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="inputCpf" className="sr-only">
                                    Cpf
                                    </Label>
                                    <Field
                                        type="text"
                                        name="cpf"
                                        id="inputCpf"
                                        placeholder="Cpf"
                                        className="form-control input-light-ec mb-2"
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="inputSenha" className="sr-only">
                                    Senha
                                    </Label>
                                    <Field
                                        type="password"
                                        name="senha"
                                        id="inputSenha"
                                        placeholder="Senha"
                                        className="form-control input-light-ec mb-2"
                                    />
                                    <ErrorMessage
                                        name="senha"
                                        component="div"
                                        className="errormessage"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="inputConfirmarSenha" className="sr-only">
                                    Confirmar senha
                                    </Label>
                                    <Field
                                        type="password"
                                        name="confirmarSenha"
                                        id="inputConfirmarSenha"
                                        placeholder="Confirmar senha"
                                        className="form-control input-light-ec mb-2"
                                    />
                                    <ErrorMessage
                                        name="confirmarSenha"
                                        component="div"
                                        className="errormessage"
                                    />
                                </FormGroup>
                                <Button
                                    disabled={isSubmitting}
                                    className="mt-3 btn-salvar"
                                    type="submit"
                                >
                                Alterar senha <MdSave color="#FFF" />
                                </Button>
                            </Form> 
                            );
                        }}
                        </Formik>
                        </div>
                    </Col>
                </Row>
            </Container>
          </div>
    </Layout>
  );
};
