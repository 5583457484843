import React, {  } from "react";
import { BoletoResponseModel } from "../../context/services/api/models";
import './boleto.css'
import Barcode from 'react-barcode';
import logoBradesco from "../../assets/logobradesco.jpg" ;
import logoBb from "../../assets/logobb.jpg" ;
import Moment from "moment";
import ReactHtmlParser from 'react-html-parser';

interface Props {
    boletoDados : BoletoResponseModel;
}

const Boleto = (props: Props) => {

    const boleto : BoletoResponseModel = props.boletoDados;
    
    let infoBanco = boleto.nomeBanco;

    if (boleto.numeroBanco == "237") {
        infoBanco = (<img src={logoBradesco}/>)
    }
    else if (boleto.numeroBanco == "001") {
        infoBanco = (<img src={logoBb}/>)
    }

    return (
        <div className="div_container_boleto">
            <table className="boleto_header">
                <tbody>
                    <tr>
                        <td width={150}>{infoBanco}</td>
                        <td width={100} className="field_cod_banco">| {boleto.numeroBanco}-{boleto.digitoBanco} | </td>
                        <td className="linha_digitavel">
                            {boleto.linhaDigitavel}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="boleto_dados">
                    <table>
                        <tbody className="boletodados_bloco">
                            <tr>
                                <th className="local_pagto _esquerdo">Local de pagamento</th>
                                <th className="vencimento _direito">Vencimento</th>
                                
                            </tr>
                            <tr>
                                <td className="local_pagto _esquerdo">{boleto.localPagamento}</td>
                                <td className="vencimento _direito direitodata">{Moment(boleto.vencimento).format("DD/MM/YYYY")}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody className="boletodados_bloco">
                            <tr>
                                <th className="cedente2 _esquerdo">Cedente</th>
                                <th className="ag_cod_cedente2 _direito direitoheader">Agência/Código cedente</th>
                            </tr>
                            <tr className="bloco_direito">
                                <td className="cedente2 campo _esquerdo">{boleto.cedente}</td>
                                <td className="ag_cod_cedente2 _direito direitodata">{boleto.agencia}</td>
                            </tr>
                        </tbody>
                    </table>
                <table>
                    <tbody className="boletodados_bloco">
                            <tr>
                                <th className="data_doc">Data do documento</th>
                                <th className="num_doc2">No. documento</th>
                                <th className="especie_doc">Espécie doc.</th>
                                <th className="aceite">Aceite</th>
                                <th className="data_process">Data process.</th>
                                <th className="nosso_numero2 _direito direitoheader">Nosso número</th>
                            </tr>
                            <tr>
                                <td className="data_doc">{Moment(boleto.emissao).format("DD/MM/YYYY")}</td>
                                <td className="num_doc2">{boleto.id}</td>
                                <td className="especie_doc">{boleto.especieDocumento}</td>
                                <td className="aceite">{boleto.aceite}</td>
                                <td className="data_process">{Moment(new Date()).format("DD/MM/YYYY")}</td>
                                <td className="nosso_numero2 _direito direitodata">{boleto.nossoNumero}</td>
                            </tr>
                    </tbody>
                </table>
                <table>
                    <tbody className="boletodados_bloco">
                        <tr>
                            <th className="reservado">Uso do banco</th>
                            <th className="carteira">Carteira</th>
                            <th className="especie2">Espécie</th>
                            <th className="qtd2">Quantidade</th>
                            <th className="xvalor">x Valor</th>
                            <th className="valor_doc2 _direito direitoheader">(=) Valor documento</th>
                        </tr>
                        <tr>
                            <td className="reservado">{boleto.codigoDaEmpresa}</td>
                            <td className="carteira">{boleto.carteira}</td>
                            <td className="especie2">{boleto.moeda}</td>
                            <td className="qtd2"></td>
                            <td className="xvalor"></td>
                            <td className="valor_doc2 _direito direitodata">
                                {boleto.vlDocumento.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="instrucoes_valores">
                    <div className="instrucoes">
                        <h1>
                            Instruções (Texto de responsabilidade do cedente)
                        </h1>
                        <p>
                            { ReactHtmlParser (boleto.obs) }
                        </p>
                    </div>
                    <div className="valores">
                        <table>
                            <tbody className="boletodados_bloco">
                                <tr>
                                    <th className="desconto2 _direito">(-) Desconto / Abatimento</th>
                                </tr>
                                <tr>
                                    <td className="desconto2 _direito direitodata">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody className="boletodados_bloco">
                                <tr>
                                    <th className="outras_deducoes2 _direito">(-) Outras deduções</th>
                                </tr>
                                <tr>
                                    <td className="outras_deducoes2 _direito direitodata">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody className="boletodados_bloco">
                                <tr>
                                    <th className="mora_multa2 _direito">(+) Mora / Multa</th>
                                </tr>
                                <tr>
                                    <td className="mora_multa2 _direito direitodata">
                                        {boleto.vlMora.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody className="boletodados_bloco">
                                <tr>
                                    <th className="outros_acrescimos2 _direito">(+) Outros Acréscimos</th>
                                </tr>
                                <tr>
                                    <td className="outros_acrescimos2 _direito direitodata">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody className="boletodados_bloco">
                                <tr>
                                    <th className="valor_cobrado _direito">(=) Valor cobrado</th>
                                </tr>
                                <tr>
                                    <td className="valor_cobrado direitodata _direito">
                                        {boleto.vlCobrado.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <table>
                        <tbody className="boletodados_bloco">
                            <tr>
                                <th className="sacado2 _esquerdo">Sacado</th>
                                <th className="_direito"></th>
                            </tr>
                            <tr>
                                <td className="sacado2 _esquerdo">
                                    <p>{boleto.nomeSocio}</p>
                                    <p>{`${boleto.enderecoFat} - ${boleto.bairroFat}`}</p>
                                    <p>{`${boleto.cepFat} ${boleto.cidadeFat} - ${boleto.estadoFat}`}</p>
                                </td>
                                <td className="_direito">CPF {boleto.cpf}</td>
                            </tr>
                        </tbody>
                    </table>

                <table className="line" cellspacing="0" cellpadding="0">
                    <tbody className="boletodados_bloco">
                        <tr>
                            <th className="sacador_avalista">Sacador/Avalista</th>
                            <th className="cod_baixa _direito direitoheader">Cód. baixa</th>
                        </tr>
                        <tr>
                            <td className="sacador_avalista _esquerdo">&nbsp;</td>
                            <td className="cod_baixa _direito direitodata">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="boleto_footer">
                <font style={{
                    fontSize: 10, textAlign: "end",
                    margin: 0
                    }}
                >Autenticação mecânica - Ficha de Compensação</font>
                <div className="barcode">
                    <Barcode 
                        value={boleto.codigoBarras} 
                        displayValue={false}
                        height={40}
                        margin={0}
                    ></Barcode>
                </div> 
            </div>
            
        </div>
    );
};

export default Boleto;