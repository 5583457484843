import React, {  } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Col, Container, Row } from "reactstrap";
import { Layout } from "../../componentes/Layout";
import { ProtecaoDeDados_Conteudo } from "../../utils/conteudoPaginas";

export const ProtecaoDeDados = () => {

  return (
      <Layout>
          <div className="pagina-container">
            <div className="headerbar p-3 mb-3">
              <Container>
                <Row>
                  <Link to="/home" className="btn-back">
                    <IoMdArrowRoundBack size={16} />
                    Voltar
                  </Link>
                </Row>
              </Container>
            </div>
            <Container>
              <Row>
                <Col className="mb-3">
                  <h2>Proteção de dados</h2>
                  <p className="text-muted W-95" style={{ fontSize: "20px"}}>
                    Informações sobre os dados que mantemos de sócios
                  </p>
                </Col>
              </Row>
              <Row>
                <p>{ProtecaoDeDados_Conteudo}</p>
              </Row>
              </Container>
          </div>
    </Layout>
  );
};
