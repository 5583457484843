import React, { useState, useRef, useContext, useEffect } from "react";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Layout } from "../../componentes/Layout";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import {
  EditarEnderecoModel
} from "../../models";
import { FaFileUpload } from "react-icons/fa";
import { toast } from "react-toastify";
import ApiService from "../../context/services/api/apiService";
import Popup from 'reactjs-popup';
import { TituloResponseModel } from "../../context/services/api/models";
import { FirstWordUpper } from "../../utils/stringUtils";
import LoadingBar from 'react-top-loading-bar';
import { EditarEndereco_ProximosPassos } from "../../utils/conteudoPaginas";

const FormSchemaEndereco = Yup.object().shape({
  estado: Yup.string().uppercase()
    .required("Informe a sigla de seu estado.")
    .max(2, "Informe somente a sigla de seu estado.")
    .test("estado-valido", "Estado inválido", function (value) {
      const estados = [
        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO',
        'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 
        'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
      return estados.some(sigla => sigla === value);
    }),
  cidade: Yup.string()
    .required("Informe a sua cidade.")
    .max(30, "O nome de sua cidade deve conter no máximo 30 caracteres."),
  numero: Yup.string()
    .required("Informe o número de seu logradouro.")
    .max(9, "O número de seu logradouro deve conter no máximo 9 caracteres."),
  logradouro: Yup.string()
    .required("Informe o seu logradouro.")
    .max(60, "Seu logradouro deve conter no máximo 60 caracteres."),
  complemento: Yup.string().trim()
    .max(60, "Seu complemento deve conter no máximo 50 caracteres."),
  bairro: Yup.string()
    .required("Informe o seu bairro.")
    .max(30, "Seu bairro deve conter no máximo 30 caracteres."),
  cep: Yup.string()
    .required("Informe o seu CEP.")
    .max(9, "Seu CEP deve conter no máximo 9 caracteres."),
  comprovante: Yup.mixed().test(
    "fileSize",
    "Por favor, envie uma foto do comprovante com tamanho máximo de 20 megabytes.",
    (value) => {
      if (value) {
        if (!value.length) return true;
        return value[0].size <= 2000000;
      }
    }
  ),
});

export const EditarEndereco = () => {
  const formRefResidencial = useRef();
  const formRefFaturamento = useRef();
  const loadingBar = useRef(null);

  const [enderecoResidencialChecked, setEnderecoResidencialChecked] =
    React.useState(true);

  const [enderecoFaturamentoChecked, setEnderecoFaturamentoChecked] =
    React.useState(false);
  
  const [ displayInfoSolicitacao, setDisplayInfoSolicitacao ] = useState(false);
  const [ tituloDados, setTituloDados ] = useState<TituloResponseModel>([]);
  
  const handleChangeToFaturamento = () => {
    setEnderecoFaturamentoChecked(true);
    setEnderecoResidencialChecked(false);
  }

  const handleChangeToResidencial = () => {
    setEnderecoFaturamentoChecked(false);
    setEnderecoResidencialChecked(true);
  }

  const onSubmitEndereco = async (endereco: EditarEnderecoModel, resetForm: Function) => {
    try {
      await ApiService.postSolicitacaoEndereco(endereco);
      toast.success("Sucesso!");
      setDisplayInfoSolicitacao(true);
      resetForm();
    } catch (error) {
      toast.error("Houve um erro ao atualizar seu endereço.");
    }
  }

  const onBlurCep = async (e, setFieldValue) => {
    const { value } = e.target;
    const cep = value?.replace(/[^0-9]/g, '');

    if (cep?.length !== 8) {
      return;
    }

    try {
      var data = await ApiService.getViaCep(cep);
      setFieldValue('logradouro', data.logradouro)
      setFieldValue('bairro', data.bairro)
      setFieldValue('cidade', data.localidade)
      setFieldValue('estado', data.uf)
    } catch (error) {
      toast.error("Houve um erro ao carregar as informações de endereço.")
    }
  }  

  useEffect(async () => {
    loadingBar.current.continuousStart();
    let resp = await ApiService.getTitulo();
    setTituloDados(resp);
    loadingBar.current.complete();
  }, []);


  return (
    <Layout>
      <LoadingBar
                height={4}
                color='#ff9138'
                ref={loadingBar}
                />
      <div className="pagina-container">
        <div className="headerbar p-3 mb-3">
          <Container>
            <Row>
              <Link to="/home" className="btn-back">
                <IoMdArrowRoundBack size={16} />
                Voltar
              </Link>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <Col>
              <h2>Meu Endereço</h2>
              <p className="text-muted W-95" style={{ fontSize: "20px"}}>
                Endereço residencial e de faturamento
              </p>
              {enderecoResidencialChecked && (
                <Formik
                  initialValues={{
                    cep: "",
                    logradouro: "",
                    bairro: "",
                    cidade: "",
                    estado: "",
                    numero: "",
                    complemento: "",
                    comprovante: undefined,
                    checkCondominio: false,
                    checkEnderecoResidencial: true,
                    checkEnderecoFaturamento: false,
                  }}
                  validationSchema={FormSchemaEndereco}
                  innerRef={formRefResidencial}
                  onSubmit={async (
                    model: EditarEnderecoModel,
                    { setSubmitting, resetForm }
                  ) => {
                    setSubmitting(false);
                    onSubmitEndereco(model, resetForm);
                  }}
                >
                  {(formik) => {
                    const { isSubmitting, errors, setFieldValue, values } =
                      formik;
                    return (
                      <>
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <button
                              className={
                                enderecoResidencialChecked
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={handleChangeToResidencial}
                              aria-current="page"
                              href="#"
                            >
                              Endereço Residencial
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={
                                enderecoFaturamentoChecked
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={handleChangeToFaturamento}
                            >
                              Endereço Faturamento
                            </button>
                          </li>
                        </ul>
                        <Form
                          className="form-editar-endereco"
                          style={{
                            backgroundColor: "#FFF",
                            padding: 15,
                          }}
                        >
                          <h5>Endereço Atual</h5>
                          <Row>
                            <Col className="pb-4">
                              <p className="mb-0">
                              {FirstWordUpper(`${tituloDados.enderecoRes ?? ''}${tituloDados.complementoRes ? ', ' + tituloDados.complementoRes : ''}`)}
                              </p>
                              <p className="mb-0">
                              {FirstWordUpper(`
                                  ${tituloDados.bairroRes ?? ''}
                                  ${tituloDados.cidadeRes ? ', '+ tituloDados.cidadeRes : ''} 
                                  ${tituloDados.estadoRes ? ', '+ tituloDados.estadoRes : ''}
                                `)}
                              </p>
                              <p className="mb-0">
                                {`CEP: ${tituloDados.cepRes ?? ''}`}
                              </p>
                            </Col>
                          </Row>
                          <h5>Atualizar Endereço</h5>
                          <Row>
                            <Col xs="12" sm="12" md="4">
                              <FormGroup>
                                <Label
                                  for="inputcepResidencial"
                                  className="sr-only"
                                >
                                  CEP *
                                </Label>
                                <Field
                                  type="text"
                                  name="cep"
                                  id="inputcepResidencial"
                                  placeholder="CEP"
                                  className="form-control input-light-ec mb-2"
                                  onBlur={(e) => onBlurCep(e, setFieldValue)}
                                />

                                <ErrorMessage
                                  name="cep"
                                  component="div"
                                  className="errormessage"
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                              <FormGroup>
                                <Label
                                  for="inputnumeroResidencial"
                                  className="sr-only"
                                >
                                  Nº *
                                </Label>
                                <Field
                                  type="number"
                                  name="numero"
                                  id="inputnumeroResidencial"
                                  placeholder="Nº"
                                  className="form-control input-light-ec mb-2"
                                />

                                <ErrorMessage
                                  name="numero"
                                  component="div"
                                  className="errormessage"
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                              <FormGroup>
                                <Label
                                  for="inputcomplementoResidencial"
                                  className="sr-only"
                                >
                                  Complemento
                                </Label>
                                <Field
                                  type="text"
                                  name="complemento"
                                  id="inputcomplementoResidencial"
                                  placeholder="Complemento"
                                  className="form-control input-light-ec mb-2"
                                />

                                <ErrorMessage
                                  name="complemento"
                                  component="div"
                                  className="errormessage"
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" sm="12" md="12">
                              <FormGroup>
                                <Label
                                  for="inputlogradouroResidencial"
                                  className="sr-only"
                                >
                                  Logradouro *
                                </Label>
                                <Field
                                  type="text"
                                  name="logradouro"
                                  id="inputlogradouroResidencial"
                                  placeholder="Logradouro"
                                  className="form-control input-light-ec mb-2"
                                  disabled
                                />

                                <ErrorMessage
                                  name="logradouro"
                                  component="div"
                                  className="errormessage"
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" sm="12">
                              <FormGroup>
                                <Label
                                  for="inputbairroResidencial"
                                  className="sr-only"
                                >
                                  Bairro *
                                </Label>
                                <Field
                                  type="text"
                                  name="bairro"
                                  id="inputbairroResidencial"
                                  placeholder="Bairro"
                                  className="form-control input-light-ec mb-2"
                                  disabled
                                />

                                <ErrorMessage
                                  name="bairro"
                                  component="div"
                                  className="errormessage"
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" sm="8">
                              <FormGroup>
                                <Label
                                  for="inputcidadeResidencial"
                                  className="sr-only"
                                >
                                  Cidade *
                                </Label>
                                <Field
                                  type="text"
                                  name="cidade"
                                  id="inputcidadeResidencial"
                                  placeholder="Cidade"
                                  className="form-control input-light-ec mb-2"
                                  disabled
                                />

                                <ErrorMessage
                                  name="cidade"
                                  component="div"
                                  className="errormessage"
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" sm="4">
                              <FormGroup>
                                <Label
                                  for="inputestadoResidencial"
                                  className="sr-only"
                                >
                                  Estado *
                                </Label>
                                <Field
                                  type="text"
                                  name="estado"
                                  id="inputestadoResidencial"
                                  placeholder="Estado"
                                  className="form-control input-light-ec mb-2"
                                  disabled
                                />

                                <ErrorMessage
                                  name="estado"
                                  component="div"
                                  className="errormessage"
                                />
                              </FormGroup>
                            </Col>
                            <Col className="mt-2" xs="12" sm="8">
                              <FormGroup className="form-check-endereco">
                                <Label check>
                                  <Field
                                    type="checkbox"
                                    name="checkCondominio"
                                    className="mt-2"
                                  />{" "}
                                </Label>
                                <span
                                  style={{
                                    fontSize: "17px"
                                  }}
                                >
                                  Eu moro em condomínio
                                </span>
                              </FormGroup>
                            </Col>
                            <Col className="mt-2" xs="12" sm="8">
                              <FormGroup className="form-check-endereco">
                                <Label check>
                                  <Field
                                    type="checkbox"
                                    name="checkEnderecoFaturamento"
                                    className="mt-2"
                                    onChange={async () => {
                                      await setFieldValue(
                                        "checkEnderecoFaturamento",
                                        !values.checkEnderecoFaturamento
                                      );
                                    }}
                                  />{" "}
                                </Label>
                                <span
                                  style={{
                                    fontSize: "17px"
                                  }}
                                >
                                  Utilizar os mesmos dados para o endereço de
                                  faturamento.
                                </span>
                              </FormGroup>
                            </Col>
                            <Col xs="12">
                              <label
                                htmlFor="uploadComprovanteResidencial"
                                className={`label-upload-comprovante-residencial btn mt-2
                                ${
                                  values.comprovante == undefined
                                    ? "btn-comprovante"
                                    : "btn-success"
                                }
                                `}
                                style={{
                                  borderColor:
                                  values.comprovante ==
                                    undefined
                                      ? "#FFF"
                                      : "#72db13",
                                  color:
                                  values.comprovante ==
                                    undefined
                                      ? "#FFF"
                                      : "#72db13",
                                }}
                              >
                                <FaFileUpload
                                  size={33}
                                  color={
                                    values.comprovante ==
                                    undefined
                                      ? "#FFF"
                                      : "#72db13"
                                  }
                                />
                                <span>
                                  {values.comprovante ==
                                  undefined
                                    ? "Envie aqui o seu comprovante residencial."
                                    : `O arquivo ${values.comprovante.name} foi selecionada com sucesso.`}
                                </span>
                              </label>
                              <input
                                type="file"
                                name="comprovante"
                                id="uploadComprovanteResidencial"
                                onChange={(e) => {
                                  setFieldValue(
                                    "comprovante",
                                    e.currentTarget.files[0]
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="comprovante"
                                component="div"
                                className="errormessage"
                              />
                            </Col>
                          </Row>
                          <Button
                            disabled={isSubmitting}
                            className="btn btn-lg btn-primary btn-block mt-3"
                            type="submit"
                          >
                            Enviar
                          </Button>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              )}

              {enderecoFaturamentoChecked && (
                <Formik
                  initialValues={{
                    cep: "",
                    logradouro: "",
                    bairro: "",
                    cidade: "",
                    estado: "",
                    numero: "",
                    complemento: "",
                    comprovante: undefined,
                    checkCondominio: false,
                    checkEnderecoResidencial: false,
                    checkEnderecoFaturamento: true
                  }}
                  validationSchema={FormSchemaEndereco}
                  innerRef={formRefFaturamento}
                  onSubmit={async (
                    model: EditarEnderecoModel,
                    { setSubmitting, resetForm }
                  ) => {
                    setSubmitting(false);
                    await onSubmitEndereco(model, resetForm);
                  }}
                >
                  {(formik) => {
                    const { isSubmitting, errors, setFieldValue, values } =
                      formik;

                    return (
                      <>
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <button
                              className={
                                enderecoResidencialChecked
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={handleChangeToResidencial}
                              aria-current="page"
                              href="#"
                            >
                              Endereço Residencial
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={
                                enderecoFaturamentoChecked
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              onClick={handleChangeToFaturamento}
                            >
                              Endereço Faturamento
                            </button>
                          </li>
                        </ul>
                        <Form
                          className="form-editar-endereco"
                          style={{
                            backgroundColor: "#FFF",
                            padding: 15,
                          }}
                        >
                          <>
                            <h5>Endereço Atual</h5>
                            <Row>
                            <Col className="pb-4">
                              <p className="mb-0">
                              {FirstWordUpper(`${tituloDados.enderecoFat ?? ''}${tituloDados.complementoFat ? ', ' + tituloDados.complementoFat : ''}`)}

                              </p>
                              <p className="mb-0">
                                {FirstWordUpper(`
                                  ${tituloDados.bairroFat ?? ''}
                                  ${tituloDados.cidadeFat ? ', '+ tituloDados.cidadeFat : ''} 
                                  ${tituloDados.estadoFat ? ', '+ tituloDados.estadoFat : ''}
                                `)}
                              </p>
                              <p className="mb-0">
                                {FirstWordUpper(tituloDados.complementoFat ?? '')}
                              </p>
                              <p className="mb-0">
                                {`CEP: ${tituloDados.cepFat ?? ''}`}
                              </p>
                            </Col>
                          </Row>
                            <h5>Atualizar Endereço</h5>
                            <Row>
                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label
                                    for="inputcepFaturamento"
                                    className="sr-only"
                                  >
                                    CEP *
                                  </Label>
                                  <Field
                                    type="text"
                                    name="cep"
                                    id="inputcepFaturamento"
                                    placeholder="CEP"
                                    className="form-control input-light-ec mb-2"
                                    onBlur={(e) => onBlurCep(e, setFieldValue)}
                                  />

                                  <ErrorMessage
                                    name="cep"
                                    component="div"
                                    className="errormessage"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="12" md="4">
                                <FormGroup>
                                  <Label
                                    for="inputnumeroFaturamento"
                                    className="sr-only"
                                  >
                                    Nº *
                                  </Label>
                                  <Field
                                    type="number"
                                    name="numero"
                                    id="inputnumeroFaturamento"
                                    placeholder="Nº"
                                    className="form-control input-light-ec mb-2"
                                  />

                                  <ErrorMessage
                                    name="numero"
                                    component="div"
                                    className="errormessage"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label
                                    for="inputcomplementoFaturamento"
                                    className="sr-only"
                                  >
                                    Complemento
                                  </Label>
                                  <Field
                                    type="text"
                                    name="complemento"
                                    id="inputcomplementoFaturamento"
                                    placeholder="Complemento"
                                    className="form-control input-light-ec mb-2"
                                  />

                                  <ErrorMessage
                                    name="complemento"
                                    component="div"
                                    className="errormessage"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="12">
                                <FormGroup>
                                  <Label
                                    for="inputlogradouroFaturamento"
                                    className="sr-only"
                                  >
                                    Logradouro *
                                  </Label>
                                  <Field
                                    type="text"
                                    name="logradouro"
                                    id="inputlogradouroFaturamento"
                                    placeholder="Logradouro"
                                    className="form-control input-light-ec mb-2"
                                    disabled
                                  />
                                  <ErrorMessage
                                    name="logradouro"
                                    component="div"
                                    className="errormessage"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="12">
                                <FormGroup>
                                  <Label
                                    for="inputbairroFaturamento"
                                    className="sr-only"
                                  >
                                    Bairro *
                                  </Label>
                                  <Field
                                    type="text"
                                    name="bairro"
                                    id="inputbairroFaturamento"
                                    placeholder="Bairro"
                                    className="form-control input-light-ec mb-2"
                                    disabled
                                  />

                                  <ErrorMessage
                                    name="bairro"
                                    component="div"
                                    className="errormessage"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="8">
                                <FormGroup>
                                  <Label
                                    for="inputcidadeFaturamento"
                                    className="sr-only"
                                  >
                                    Cidade *
                                  </Label>
                                  <Field
                                    type="text"
                                    name="cidade"
                                    id="inputcidadeFaturamento"
                                    placeholder="Cidade"
                                    className="form-control input-light-ec mb-2"
                                    disabled
                                  />

                                  <ErrorMessage
                                    name="cidade"
                                    component="div"
                                    className="errormessage"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label
                                    for="inputestadoFaturamento"
                                    className="sr-only"
                                  >
                                    Estado *
                                  </Label>
                                  <Field
                                    type="text"
                                    name="estado"
                                    id="inputestadoFaturamento"
                                    placeholder="Estado"
                                    className="form-control input-light-ec mb-2"
                                    disabled
                                  />

                                  <ErrorMessage
                                    name="estado"
                                    component="div"
                                    className="errormessage"
                                  />
                                </FormGroup>
                              </Col>
                              <Col className="mt-2" xs="12" sm="8">
                              <FormGroup className="form-check-endereco">
                                <Label check>
                                  <Field
                                    type="checkbox"
                                    name="checkCondominio"
                                    className="mt-2"
                                  />{" "}
                                </Label>
                                <span
                                  style={{
                                    fontSize: "17px"
                                  }}
                                >
                                  Eu moro em condomínio
                                </span>
                              </FormGroup>
                            </Col>
                              <Col xs="12">
                                <label
                                  htmlFor="uploadComprovanteFaturamento"
                                  className={`label-upload-comprovante-residencial btn mt-2
                                ${
                                  values.comprovante == undefined
                                    ? "btn-comprovante"
                                    : "btn-success"
                                }
                                `}
                                  style={{
                                    borderColor:
                                    values.comprovante ==
                                      undefined
                                        ? "#FFF"
                                        : "#72db13",
                                    color:
                                    values.comprovante ==
                                      undefined
                                        ? "#FFF"
                                        : "#72db13",
                                  }}
                                >
                                  <FaFileUpload
                                    size={33}
                                    color={
                                      values.comprovante ==
                                      undefined
                                        ? "#FFF"
                                        : "#72db13"
                                    }
                                  />
                                  <span>
                                    {values.comprovante ==
                                    undefined
                                      ? "Envie aqui o seu comprovante residencial."
                                      : `O arquivo ${values.comprovante.name} foi selecionada com sucesso.`}
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  name="comprovanteFaturamento"
                                  id="uploadComprovanteFaturamento"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "comprovante",
                                      e.currentTarget.files[0]
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="comprovante"
                                  component="div"
                                  className="errormessage"
                                />
                              </Col>
                            </Row>
                          </>
                          <Button
                            disabled={isSubmitting}
                            className="btn btn-lg btn-primary btn-block mt-3"
                            type="submit"
                          >
                            Enviar
                          </Button>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              )}
            </Col>
            <Popup 
              open={displayInfoSolicitacao} 
              position="center center"
              onClose={() => setDisplayInfoSolicitacao(false)}
            >
                <div className="p-5 text-center bg-light rounded border border-primary">
                  <h2>
                    Próximos passos
                  </h2>
                  <p
                    style={{
                      fontSize: "17px"
                    }}
                  >
                    {EditarEndereco_ProximosPassos}
                  </p>
                  <Button
                    className="w-50" 
                    onClick={() => setDisplayInfoSolicitacao(false)}
                  >
                    Ok
                  </Button>
                </div>
            </Popup>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};
