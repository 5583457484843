import { MinhaConta } from "./paginas/minha-conta/MinhaConta";
import { Home } from "./paginas/home/Home";
import { MeuTitulo } from "./paginas/meu-titulo/MeuTitulo";
import { MeusBoletos } from "./paginas/meus-boletos/MeusBoletos";
import { AlterarDados } from "./paginas/alterar-dados/AlterarDados";
import { EditarEndereco } from "./paginas/editar-endereco/EditarEndereco";
import { ImprimirBoleto } from "./paginas/imprimir-boleto/ImprimirBoleto";
import { Cadastro } from "./paginas/cadastro/Cadastro";
import { ProtecaoDeDados } from "./paginas/protecao-de-dados/ProtecaoDeDados";
import { EsqueciMinhaSenha } from "./paginas/esqueci-minha-senha/EsqueciMinhaSenha";
import { AlterarSenha } from "./paginas/alterar-senha/AlterarSenha";
import { Pagamento } from "./paginas/pagamento/Pagamento";
import { Mural } from "./paginas/mural/Mural";
class RouteConfigItem implements IRouteConfigItem {
  constructor(parameters: IRouteConfigItem) {
    this.path = parameters.path;
    this.component = parameters.component;
  }
  path: string;
  component: any;
  buildUrl(parameters: any) {
    var pathReturn = this.path;
    for (const key of Object.keys(parameters)) {
      pathReturn = pathReturn.replace(`:${key}`, parameters[key]);
    }
    return pathReturn;
  }
}

interface IRouteConfigItem {
  path: string;
  component: any;
}

export default class RouteConfig {
  static entrar = new RouteConfigItem({
    path: "/",
    component: MinhaConta,
  });

  static home = new RouteConfigItem({
    path: "/home",
    component: Home,
  });

  static meuTitulo = new RouteConfigItem({
    path: "/meu-titulo",
    component: MeuTitulo,
  });

  static cadastro = new RouteConfigItem({
    path: "/cadastro",
    component: Cadastro,
  });

  static meusBoletos = new RouteConfigItem({
    path: "/meus-boletos",
    component: MeusBoletos,
  });

  static alterarDados = new RouteConfigItem({
    path: "/alterar-dados",
    component: AlterarDados,
  });

  static imprimirBoleto = new RouteConfigItem({
    path: "/imprimir-boleto",
    component: ImprimirBoleto,
  });

  static protecaoDeDados = new RouteConfigItem({
    path: "/protecao-de-dados",
    component: ProtecaoDeDados,
  });

  static esqueciMinhaSenha = new RouteConfigItem({
    path: "/esqueci-minha-senha",
    component: EsqueciMinhaSenha,
  });

  static alterarSenha = new RouteConfigItem({
    path: "/alterar-senha",
    component: AlterarSenha,
  });

  static pagamento = new RouteConfigItem({
    path: "/pagamento",
    component: Pagamento,
  });

  static editarEndereco = new RouteConfigItem({
    path: "/editar-endereço",
    component: EditarEndereco,
  });

  static mural = new RouteConfigItem({
    path: "/mural",
    component: Mural,
  });
}
