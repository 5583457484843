import { ErrorMessage, Field, Form, Formik, isEmptyArray } from "formik";
import React, { Component, useState, useCallback, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { SolicitacaoLoginModel } from "../../models";
import lendario from "../../assets/lendario.jpg";
import FotoSocioDoc from "../../assets/FotoSocioDoc.jpg";
import { MdAddPhotoAlternate, MdEmail, MdTrendingUp } from "react-icons/md";
import { toast } from "react-toastify";
import ApiService from "../../context/services/api/apiService";
import { Layout } from "../../componentes/Layout";
import LoadingBar from 'react-top-loading-bar';
import { Cadastro_ProximosPassos } from "../../utils/conteudoPaginas";

import { CadastroModel, ValidarIdentidadeModel } from "../../models";

const FormSchema = Yup.object().shape({
  nrTitulo: Yup.string().required("Informe o número do título."),
  dataDeNascimento: Yup.date()
    .required("Informe a data de nascimento.")
    .nullable()
    .max(
      new Date(Date.now()),
      "A data de nascimento não pode ser maior que hoje."
    )
    .default(undefined),
  nomeCompleto: Yup.string()
    .required("Informe o seu nome completo.")
    .min(2, "Informe o seu nome completo.")
    .max(60, "Informe um nome de no máximo 60 caracteres."),
  cpf: Yup.string()
    .trim()
    .required("Informe o seu CPF.")
    .matches(
      /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2})+$/,
      "Informe um CPF válido."
    )
    .max(14, "Informe um CPF válido."),
  email: Yup.string()
    .required("Informe o seu e-mail.")
    .email("Informe um e-mail válido.")
    .max(50, "Informe um e-mail válido."),
  senha: Yup.string()
    .required("Informe uma senha com ao menos 5 caracteres.")
    .min(5, "Informe uma senha com ao menos 5 caracteres.")
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])/,
      "A senha precisa conter pelo menos um número e uma letra."
    ),
  confirmarSenha: Yup.string()
    .required("Informe uma senha com ao menos 5 caracteres.")
    .min(5, "Informe uma senha com ao menos 5 caracteres.")
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])/,
      "A senha precisa conter pelo menos um número e uma letra."
    )
    .test("senhas-match", "As senhas precisam ser iguais", function (value) {
      return this.parent.senha === value;
    }),
  protecaoDados: Yup.boolean().isTrue(
    "É preciso aceitar os termos para prosseguir."
  ),
  uploadImagem: Yup.mixed().test(
    "fileSize",
    "Por favor envie uma foto com tamanho máximo de 20 megabytes.",
    (value) => {
      if (value) {
        if (!value.length) return true;
        return value[0].size <= 2000000;
      }
    }
  ),
});

export const Cadastro = (props) => {
  const [isSegundoPasso, setIsSegundoPasso] = useState(false);
  const [isCadastroFinalizado, setIsCadastroFinalizado] = useState(false);
  const [imagemSelecionada, setImagemSelecionada] = useState("");

  const loadingBar = useRef(null);

  const validarTitularAsync = async (titulo, cpf, nascimento, setFieldValue) => {
    loadingBar.current.continuousStart();
    var identidadeModel: ValidarIdentidadeModel = {
      nascimento: nascimento,
      cpf: cpf.trim().replace(/\D/g, ""),
      titulo: titulo.trim(),
    };

    try {
      var valido = await ApiService.validarIdentidadeAsync(identidadeModel);
      if (valido) {
        setIsSegundoPasso(true);
        setFieldValue('cpf', cpf.trim().replace(/\D/g, ""))
      } else {
        toast.warning(
          "Não foi possível achar nenhum título que corresponde a essas informações."
        );
      }
    } catch {
      toast.error("Houve um erro ao verificar sua identidade.");
    }

    loadingBar.current.complete();
  };

  const onSubmitCadastro = async (model: CadastroModel) => {
    loadingBar.current.continuousStart();

    let emailJaCadastrado = await ApiService.verificarEmailCadastrado(
      model.email
    );

    if (emailJaCadastrado) {
      toast.error("Email já cadastrado.");
      throw new Error("Email já cadastrado.");
    } else {
      let solicitacao: SolicitacaoLoginModel = {
        nrTitulo: model.nrTitulo,
        cpf: model.cpf,
        nomeCompleto: model.nomeCompleto,
        email: model.email,
        senha: model.senha,
        imagem: model.uploadImagem,
      };
      await ApiService.inserirSolicitacaoLogin(solicitacao);
    }

    loadingBar.current.complete();
  };

  return (
    <Layout hideMenuToggler={true}>
      <LoadingBar
                height={4}
                color='#ff9138'
                ref={loadingBar}
                />
      <div className="bg-secondary-color minha-conta-login-wrapper">
        <h1 className="h3 mb-3 font-weight-normal title text-center title-light">
          Cadastro
        </h1>
        <div className="minha-conta-login-container">
          {!isCadastroFinalizado ? (
            <Formik
              initialValues={{
                nrTitulo: "",
                cpf: "",
                dataDeNascimento: undefined,
                nomeCompleto: "",
                email: "",
                senha: "",
                protecaoDados: false,
                uploadImagem: null,
              }}
              validationSchema={FormSchema}
              onSubmit={async (model: CadastroModel, { setSubmitting }) => {
                setSubmitting(false);
                try {
                  await onSubmitCadastro(model);
                  toast.success("Sucesso!");
                  setIsCadastroFinalizado(true);
                } catch {
                  toast.error("Houve um erro ao concluir o cadastro.");
                }
              }}
            >
              {(formik) => {
                const {
                  isSubmitting,
                  errors,
                  setFieldValue,
                  validateField,
                  validateForm,
                  touched,
                  values,
                } = formik;

                return (
                  <Form className="form-signin">
                    {!isSegundoPasso ? (
                      <>
                        <FormGroup>
                          <Label for="inputnrTitulo" className="sr-only">
                            Número do título
                          </Label>
                          <Field
                            type="text"
                            name="nrTitulo"
                            id="inputnrTitulo"
                            placeholder="Número do título"
                            className="form-control input-light-ec mb-2"
                          />
                          {touched.nrTitulo && errors.nrTitulo && (
                            <div className="errormessage">
                              {errors.nrTitulo}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="inputcpf" className="sr-only">
                            CPF do titular
                          </Label>
                          <Field
                            type="text"
                            name="cpf"
                            id="inputcpf"
                            placeholder="CPF"
                            className="form-control input-light-ec mb-2"
                          />
                          {touched.cpf && errors.cpf && (
                            <div className="errormessage">{errors.cpf}</div>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="dataDeNascimento" className="formlabel">
                            Data de nascimento do titular
                          </Label>
                          <Field
                            name="dataDeNascimento"
                            type="date"
                            placeholder="Data de nascimento do titular"
                            className="form-control"
                            id="dataDeNascimento"
                          />
                          {touched.dataDeNascimento &&
                            errors.dataDeNascimento && (
                              <div className="errormessage">
                                {errors.dataDeNascimento}
                              </div>
                            )}
                        </FormGroup>
                        <Button
                          className="btn btn-lg btn-primary btn-block mt-3"
                          onClick={() => {
                            validateForm().then(() => {
                              validarTitularAsync(
                                values.nrTitulo,
                                values.cpf,
                                values.dataDeNascimento,
                                setFieldValue
                              );
                            });
                          }}
                        >
                          Avançar
                        </Button>
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="upload-photo"
                          className="label-upload-photo"
                          style={{
                            borderColor:
                              imagemSelecionada == "" ? "#000" : "#72db13",
                            color: imagemSelecionada == "" ? "#000" : "#72db13",
                          }}
                        >
                          <img src={FotoSocioDoc} height="110"/>
                          <br />
                          {imagemSelecionada == ""
                            ? "Envie a sua foto aqui, segurando um documento com foto." +
                              "(RG, CNH, etc)"
                            : `A imagem ${imagemSelecionada} foi selecionada com sucesso.`}
                        </label>
                        <input
                          type="file"
                          name="uploadImagem"
                          id="upload-photo"
                          onChange={(e) => {
                            let nomeImagemArr = e.target.value.split("\\");
                            setImagemSelecionada(
                              nomeImagemArr[nomeImagemArr.length - 1]
                            );
                            setFieldValue(
                              "uploadImagem",
                              e.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage
                          name="uploadImagem"
                          component="div"
                          className="errormessage"
                        />
                        <FormGroup>
                          <Label for="nomeCompleto" className="sr-only">
                            Nome completo
                          </Label>
                          <Field
                            type="text"
                            name="nomeCompleto"
                            id="nomeCompleto"
                            placeholder="Nome completo"
                            className="form-control input-light-ec mb-2"
                          />

                          <ErrorMessage
                            name="nomeCompleto"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="email" className="sr-only">
                            Email
                          </Label>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            className="form-control input-light-ec mb-2"
                          />

                          <ErrorMessage
                            name="email"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="inputPassword" className="sr-only">
                            Senha
                          </Label>
                          <Field
                            type="password"
                            name="senha"
                            id="inputPassword"
                            placeholder="Senha"
                            className="form-control  input-light-ec"
                          />

                          <ErrorMessage
                            name="senha"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputConfirmarSenha" className="sr-only">
                            Confirmar senha
                            </Label>
                            <Field
                                type="password"
                                name="confirmarSenha"
                                id="inputConfirmarSenha"
                                placeholder="Confirmar senha"
                                className="form-control input-light-ec mb-2"
                            />
                            <ErrorMessage
                                name="confirmarSenha"
                                component="div"
                                className="errormessage"
                            />
                        </FormGroup>
                        <FormGroup check className="form-check-cadastro">
                          <Label check>
                            <Field
                              type="checkbox"
                              name="protecaoDados"
                              className="mt-2"
                            />{" "}
                          </Label>
                          <span>
                            {" "}
                            Sim, estou de acordo que os meus dados cadastrais
                            ficarão disponíveis somente para mim aqui na área do
                            sócio.
                          </span>

                          <ErrorMessage
                            name="protecaoDados"
                            component="div"
                            className="errormessage"
                          />
                        </FormGroup>

                        <Button
                          disabled={isSubmitting}
                          className="btn btn-lg btn-primary btn-block mt-3"
                          type="submit"
                        >
                          Enviar
                        </Button>
                      </>
                    )}
                    <Link to="/" className="mt-2">
                      <p>Já tem uma conta? Entre</p>
                    </Link>
                    <Link to="">
                      <p>Esqueci a minha senha</p>
                    </Link>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Container>
              <h3>Próximos passos</h3>  
              {Cadastro_ProximosPassos}
              <Link to="/">
                <p>Voltar para página inicial</p>
              </Link>
            </Container>
          )}
        </div>
        <div
          style={{ backgroundImage: `url(${lendario})` }}
          className="cadastro-bg-image"
        ></div>
      </div>
    </Layout>
  );
};
