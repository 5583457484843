import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "../componentes/nav-menu/NavMenu";
import Footer from "./Footer";
import Fundo from '../assets/Fundo.png'
interface Props {
  hideMenuToggler?: boolean;
  children?: any;
}

const Layout = (props: Props) => {
  return (
    <div >
      <NavMenu hideMenuToggler={props.hideMenuToggler} />
      <Container style={{minHeight: "80vh", backgroundImage: `url(${Fundo})`,  backgroundSize:'cover', backgroundRepeat:'no-repeat'}}
        fluid
        cssModule={{ paddingRight: "0px !important", paddingLeft: "0px" }}
        >
        {props.children}
      </Container>
      <Footer />
    </div>
  );
};

export { Layout };
